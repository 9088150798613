import React from 'react';
import Layout from "../../components/layout";

class EighthDan extends React.Component {
    render() {
        return (
            <Layout>
                <h1>Sensei Graham Ravey - 8th Dan</h1>

                <p>Sensei Ravey was presented with his 8th Dan during 'The Elements' seminar in Huddersfield - England.
                    Supported by selected peers and associations, certificates and letters were complied in a portfolio
                    for the presentation.</p>

                <p>Contributors were:<br/>
                    International Society of Okinawa/Japanese Karate-do signed by Vince Morris and co-signed by Harry
                    Cook.
                    Koryu Kenkai Australia signed by Liam Keeley who trained with Sensei Ravey in Sensei Higaonna's
                    Yoyogi dojo.
                    International Ryukyu Karate Research Society signed by Patrick McCarthy (who translated the
                    Bubishi).
                    The British Karate Association signed by Brian Seabright.</p>
                <p>Zanshin Martial Arts/European Budo Federation signed by Dave Allen and Eurpean Karate-do Wado Kai
                    signed by Walter Seaton (both men were Dansha under Phil Milner (Sensei's first Chief Instructor)
                    and helped organise the ninja courses Sensei refers to in his book.</p>
            </Layout>);
    }
}

export default EighthDan;
